@import url(https://fonts.googleapis.com/css?family=Ubuntu|Ubuntu+Condensed&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


/*OVDJE POČINJE CSS IZ STAROG PROJEKTA BIBLIO*/

.btn-secondary {
  /*background-color: #6b5d62ff !important;*/
  background-color: #4d4d4dff !important;
  font-family: 'Ubuntu', sans-serif;
  border: none;
  color: white;
}

.btn-secondary:focus {
  background-color: #6b5d62ff !important;
}

.btn-secondary:active {
  background-color: #6b5d62ff !important;

}

.btn-secondary:hover {
  background-color: #6b5d62ff !important;
}


.btn-light-back {
  background-color:lightgray;
}


.btn-primary {
  background-color: #4d4d4dff;
  font-family: 'Ubuntu', sans-serif;
  border: none;
  color: white;
}

.row .col-md-9 .btn-primary:focus {
  background-color: #6b5d62ff;
  box-shadow: none;
}

.row .col-md-9 .btn-primary:active:focus {
  box-shadow: 0 0 0 0.1rem #9999997f;
}

.row .col-md-9 .btn-primary:active {
  background-color: #6b5d62ff;
}

.row .col-md-9 .btn-primary:hover {
  background-color: #6b5d62ff;
  border: none;
  /*box-shadow: 0 0 0 0.1rem #9999997f;*/
  box-shadow: none;
}

.row .col-md-3 .btn-primary:focus {
  background-color: #6b5d62ff;
  box-shadow: none;
  color: white;
}

.row .col-md-3 .btn-primary:active:focus {
  box-shadow: 0 0 0 0.1rem #9999997f;
  background-color: #6b5d62ff;
}

.row .col-md-3 .btn-primary:active {
  background-color: #6b5d62ff;

}

.row .col-md-3 .btn-primary:hover {
  background-color: #6b5d62ff;
  border: none;
  /*box-shadow: 0 0 0 0.1rem #9999997f;*/
  box-shadow: none;
}



.searchbar .input-group .btn-primary:focus {
  background-color: #6b5d62ff;
  box-shadow: none;
}

.searchbar .input-group .btn-primary:active:focus {
  box-shadow: 0 0 0 0.1rem #9999997f;
}

.searchbar .input-group .btn-primary:active {
  background-color: #6b5d62ff;
}

.searchbar .input-group .btn-primary:hover {
  background-color: #6b5d62ff;
  /*box-shadow: 0 0 0 0.1rem #9999997f;*/
  box-shadow: none;
}

.searchbar form {
  width: 100%;
}


.segoe {
  font-family: "Segoe UI";
  font-weight: 200;
  color: #4d4d4dff;
}

.top-image {
  max-width: 20%;
  height: auto;
  margin: 10px;
}

@media (max-width: 1000px) {

  .top-image {
    width: auto;
    height: 50px;
    margin: 10px;
  }

}

.logo-image {
  max-width: 20%;
  height: auto;
  margin: 10px;
}

@media (max-width: 1000px) {

  .logo-image {
    max-width: 80%;
    height: 60px;
    margin: 10px;
  }

}


.top-logo {
  max-width: 20%;
  max-height: 50px;
  margin: 10px;
}

.center {
  text-align: center;

}


.search-btn {
  text-align: left;
  vertical-align: middle;
}

.search-btn-style {
  height: 40px;
  width: 50px;
}

@media (max-width: 1000px) {
  .search-btn-style{
    height: 40px;
    width: 100%;
  }
}

.dark-gray-back {
  background-color: #4d4d4dff;
}


.ubuntu {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 200;
  color: rgb(155, 150, 150);
}


.ubuntu-white {
  color: white;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
}


.ubuntu-italic-light {
  color: #999999ff;
  font-family: 'Ubuntu Condensed', sans-serif;
  font-style: italic;


}



.ubuntu-link {
  color: #999999ff !important;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
}

.ubuntu-link:hover {
  color: white !important;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
}

.ubuntu-link5 {
  color: #999999ff !important;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
  border: none;
  background-color: white;
}

.ubuntu-link5:hover {
  color: #4d4d4dff !important;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
  border: none;
  background-color: white;
}

a.ubuntu-link2 {
  color: #999999ff !important;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
  border-top: 3px solid white;
}

a.ubuntu-link2:hover {
  color: #4d4d4dff !important;
  font-family: 'Ubuntu Condensed', sans-serif;
  border-top: 3px solid #a5af93ff;
}

a.ubuntu-link3 {
  color: #999999ff !important;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
  border-top: 3px solid white;
}

a.ubuntu-link3:hover {
  color: #4d4d4dff !important;
  font-family: 'Ubuntu Condensed', sans-serif;
  border-top: 3px solid #6b5d62ff;
}

a.ubuntu-link4 {
  color: #999999ff !important;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
  border-top: 3px solid white;
}

a.ubuntu-link4:hover {
  color: #4d4d4dff !important;
  font-family: 'Ubuntu Condensed', sans-serif;
  border-top: 3px solid #b3a1a1ff;
}


.ubuntu-dark {
  color: #4d4d4dff;
  font-family: 'Ubuntu', sans-serif;
  text-decoration: none;
}

.ubuntu-red {
  color: darkred;
  font-family: 'Ubuntu', sans-serif;
  text-decoration: none;
}

.ubuntu-light {
  color: #999999ff;
  font-family: 'Ubuntu', sans-serif;
  text-decoration: none;
}

.ubuntu-dark-cond {
  color: #4d4d4dff;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
}

.ubuntu-light-cond-invert {
  font-size: smaller;
  background-color: #999999ff;
  color: white;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
}

.ubuntu-light-cond {
  color: white;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
}

.ubuntu-red-cond {
  color: darkred;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
  font-size: small;
}

.ubuntu-green-cond-invert {
  font-size: smaller;
  background-color: #a5af93ff;
  color: white;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
}

.ubuntu-beige-cond-invert {
  font-size: smaller;
  background-color: #b3a1a1ff;
  color: white;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
}

.ubuntu-brown-cond-invert {
  font-size: smaller;
  background-color: #6b5d62ff;
  color: white;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
}

.ubuntu-dark-cond-invert {
  font-size: smaller;
  background-color: #4d4d4dff;
  color: white;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;


}

.ubuntu-gray {
  color: #999999ff !important;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
}

.ubuntu-gray-small {
  color: #999999ff !important;
  font-family: 'Ubuntu Condensed', sans-serif;
  font-size: smaller;
  text-decoration: none;
}

.light-gray-back {
  background-color: #999999ff;
}

.lr-padding {

  padding-left: 10px;
  padding-right: 10px;
}

.lr-padding-rem {

  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.float-right {
  float: right;
  align-content: flex-end;
}

.border-bottom {
  border-bottom: 1px;
  border-bottom-color: #dee2e6;
}

.border-right {

  border-right: 1px;
  border-right-style: solid;
  border-right-color: #dee2e6;
}

.border-left {

  border-left: 3px;
  border-left-color: #999999ff;
}

.padding15 {
  padding: 15px;
}

.padding5 {
  padding: 5px;
}
.padding3 {
  padding: 3px;
}

.padding-icon {
  padding-left: 0px;

}

.padding-top5 {
  padding-top: 5px;
}


.lr-padding-large {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 1000px) {
  .lr-padding-large {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

  }

}

.login-corner {
  padding-top: 5px;
  margin: auto;
  text-align: right;
}

.footer-fixed {
  position: absolute;
  width: 50%;
  bottom: 0;
  /*height: 60px;
//line-height: 60px;*/
}


.footer-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.padding-top-bottom {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding-bottom {
  padding-bottom: 10px;
}

.padding-bottom-large {
  padding-bottom: 40px;
}

.align-right {
  text-align: right;
}

.align-left-right {
  text-align: right;
  border-right: 1px;
  border-right-style: solid;
  border-right-color: #dee2e6;
}

@media (max-width: 1000px) {
  .align-left-right {
    border-top: 0.25px solid #dee2e6;
    border-right: none;
    text-align: left;
  }
}

@media (max-width: 1000px) {
  .mobile-right {
    padding: 5px;
    text-align: right;
  }
}

.full-width {
  width: 100%;
}

select {
  color: #4d4d4dff;
  outline-color: #4d4d4dff;
}

.datepicker {
  width: 70%;
}

.faded {
  color: rgba(0, 0, 0, 0.3);
}

/*
// input{  
//   color: #4d4d4dff;
//   outline-color: #4d4d4dff;
// }

// input:hover{
//   color: #4d4d4dff;
//   outline-color: #4d4d4dff;  
// }
// input:focus{
//   outline-color: #4d4d4dff;
// }

// input:active:focus{
//   outline-color: #4d4d4dff;
// }

*/

input[type='text'],
input[type='date'],
s input,
textarea,
select,
option {
  padding-left: 3px;
  color: #4d4d4dff;
  border: 0.5px solid #999999ff;
  outline-color: #999999ff;
}

input[type="text"]:hover,
input[type="checkbox"]:hover,
input:hover,
textarea:hover,
select:hover {
  color: #4d4d4dff;
  background-color: #fff;
  border: 0.5px solid #999999ff;
  box-shadow: 0 0 0 0.1rem #9999997f;
}

input[type='text']:focus,
input[type='checkbox']:focus,
input:focus,
select:focus {
  color: #4d4d4dff;
  background-color: #fff;
  border-color: #999999ff !important;

  box-shadow: 0 0 0 0.1rem #9999997f;
}

input[type='text']:active:focus,
input[type='checkbox']:active:focus,
input:active:focus,
select:active:focus {
  color: #4d4d4dff;
  background-color: #fff;
  border-color: #999999ff;
  outline: 0;
  outline-color: #999999ff;
  box-shadow: 0 0 0 0.1rem #9999997f;
}






.ubuntu-result {
  color: #4d4d4dff;
  font-family: 'Ubuntu Condensed', sans-serif;
  font-weight: lighter;
  text-decoration: none;
  padding: none;
}

.ubuntu-result-bold {
  color: #4d4d4dff;
  font-weight: bold;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
  padding: none;
}

.ubuntu-result-bold:hover {
  color: #6b5d62ff;
  font-weight: bold;
  font-family: 'Ubuntu Condensed', sans-serif;
  text-decoration: none;
  padding: none;
}

.ubuntu-link-in-text {
  color: #4d4d4dff;
  font-family: 'Ubuntu', sans-serif;
  text-decoration: underline;
  padding: none;
}

.ubuntu-link-in-text:hover {
  color: #6b5d62ff;
  font-family: 'Ubuntu', sans-serif;
  text-decoration: underline;
  padding: none;
}

.ubuntu-link-in-text:visited {
  color: #4d4d4dff;
  font-family: 'Ubuntu', sans-serif;
  text-decoration: underline;
  padding: none;
}


.searchbar .form-control {
  border: 1px solid #ced4da;
}

.searchbar .form-control:hover {
  color: #4d4d4dff;
  background-color: #fff;
  border: 1px solid #999999ff;
  /*border-color: #999999ff;*/
  outline: 0;
  outline-color: #999999ff;
  box-shadow: 0 0 0 0.1rem #9999997f;

}

.searchbar .form-control:focus {
  color: #4d4d4dff;
  background-color: #fff;
  border: 1px solid #999999ff;
  /*border-color: #999999ff;*/
  outline: 0;
  outline-color: #999999ff;
  box-shadow: 0 0 0 0.1rem #9999997f;

}

.searchbar .form-control:active:focus {
  color: #4d4d4dff;
  background-color: #fff;
  border: 1px solid #999999ff;
  /*border-color: #999999ff;*/
  outline: 0;
  outline-color: #999999ff;
  box-shadow: 0 0 0 0.1rem #9999997f;
}





a {
  color: #4d4d4dff;
}

a:hover {
  color: #6b5d62ff;
  text-decoration: none;
}



.rounded-corners-bottom {
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}


.rounded-corners-top {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.rounded-corners {
  border-radius: 10px !important;
}

.searchbar .left-padded-input {
  padding-left: 10px;
}


.searchbar-input {
  height: 40px;
  width: 100%;
}

.nice-textbox {
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

/* The radio */
.radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 2px;
  cursor: pointer;
  /*font-size: 10px;*/
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkround {

  position: absolute;
  top: 6px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-color: #4d4d4dff;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
}


/* When the radio button is checked, add a blue background */
.radio input:checked~.checkround {
  background-color: #4d4d4dff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkround:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked~.checkround:after {
  display: block;
}

/*
// /* Style the indicator (dot/circle) */
/* .radio .checkround:after {
//  left: 0px;
//  top: 0px;
//  width: 16px;
//  height: 16px;
//  border-radius: 50%;

//  background:#4d4d4dff;

// }


// .radio input:hover ~ .checkround {
//   background-color: #fff;
//  }
*/
.hide-clipboard {
  opacity: 0;
  height: 0;
}

.btn-simple {
  outline: 0.5px solid #9999997f;
  border: none;
  box-shadow: none;
  background-color: white;
  color: #999999ff;
}

.btn-simple:focus {
  outline: 0.5px solid #9999997f;
  border: none;
  box-shadow: none;
  background-color: white;
  color: #999999ff;
}

.btn-simple:active {
  outline: 0.5px solid #9999997f;
  border: none;
  box-shadow: none;
  background-color: white;
  color: #999999ff;
}

.btn-simple:active:focus {
  outline: 0.5px solid #9999999f;
  border: none;
  box-shadow: none;
  background-color: white;
  color: #b3a1a1ff;
}

.btn-simple:hover {
  outline: 0.5px solid #999999ff;
  border: none;
  box-shadow: none;
  background-color: white;
  color: #4d4d4dff;
}


.btn-simple2 {
  border: none;
  box-shadow: none;
  background-color: white;
  color: #999999ff;
}

.btn-simple2:focus {
  border: none;
  box-shadow: none;
  background-color: white;
  color: #999999ff;
}

.btn-simple2:active {
  border: none;
  box-shadow: none;
  background-color: white;
  color: #999999ff;
}

.btn-simple2:active:focus {
  border: none;
  box-shadow: none;
  background-color: white;
  color: #b3a1a1ff;
}

.btn-simple2:hover {
  border: none;
  box-shadow: none;
  background-color: white;
  color: #4d4d4dff;
}

.loader-container {
  /*
  width: 100vw;
  height: 70vh;
  display: flex;
  float: left;
  
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;

  margin: 0;
  padding: 0;
*/

  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin: 0;
  padding: 0;
  display: flex;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #a5af93ff transparent #a5af93ff transparent;
  border-radius: 50%;
  -webkit-animation: spin-anim 1.2s linear infinite;
          animation: spin-anim 1.2s linear infinite;

}

@-webkit-keyframes spin-anim {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin-anim {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*
// ---------------
//    Variables   
// ---------------

$dropdown-main-color: rgba(46, 46, 46, 0.4);
$dropdown-basic-height: 40px;
$dropdown-basic-border-width: 2px;
$dropdown-basic-border-radius: 2px;

// -------------
//    Styling   
// -------------

.jui-dropdown {
  position: relative;
  position: inline-block;
}

.jui-dropdown__caret {
  vertical-align: middle;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 12px;
  color: $dropdown-main-color;
  cursor: pointer;
  z-index: 1;
  pointer-events: none;
  padding-top: 10px;
  
  &:before {
      // Has to be replaced with JimdoIconFont
      font-family: "FontAwesome";
      content: "\f0d7";
  }
}

.jui-dropdown__select {
  border-radius: $dropdown-basic-border-radius;
  border: $dropdown-basic-border-width solid $dropdown-main-color;
  box-sizing: border-box;
  font-family: 'Ubuntu Condensed', sans-serif;
  font-size: 16px;
  height: $dropdown-basic-height;
  margin: 4px 0;
  padding: 0 27px 0 10px; 
  user-select: none;
  cursor: pointer;
  text-overflow: ellipsis;
  outline: none;
  background: white;
  appearance: none;
}

// Disabled state
.jui-dropdown--disabled {
  opacity: 0.5;
  pointer-events: none;
  
  .jui-dropdown__select,
  .jui-label,
  .jui-dropdown__caret {
      cursor: default !important;
  }
}

// ----------------
//    UI Library  
// ----------------

.jui-label {
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}
*/
